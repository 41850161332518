import React from "react";

function LoaderIcon({ className }) {
    return (
        <div className={className.wrapper}>
            <svg
                data-testid="loader-icon"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                height="32"
                width="32"
                version="1.1"
                viewBox="0 0 32 32"
                className={className.icon}
                // xmlSpace="preserve"
            >
                <circle cx="5" cy="16" r="3" fill="">
                    <animate
                        attributeName="opacity"
                        begin="0.1"
                        dur="1s"
                        repeatCount="indefinite"
                        values="0;1;0"
                    ></animate>
                </circle>
                <circle cx="15" cy="16" r="3" fill="">
                    <animate
                        attributeName="opacity"
                        begin="0.2"
                        dur="1s"
                        repeatCount="indefinite"
                        values="0;1;0"
                    ></animate>
                </circle>
                <circle cx="25" cy="16" r="3" fill="">
                    <animate
                        attributeName="opacity"
                        begin="0.3"
                        dur="1s"
                        repeatCount="indefinite"
                        values="0;1;0"
                    ></animate>
                </circle>
            </svg>
        </div>
    );
}

export default LoaderIcon;

import React, { useEffect, useState } from "react";
import Icon from "@components/Icon";

const DropDownItem = ({ value, setValue }) => {
    return (
        <div
            className="px-3 py-1 flex-none h-10 flex gap-2.5 items-center transition-all active:bg-grey-500 select-none rounded-8 text-grey-300 hover:bg-grey-600 hover:text-grey-100 cursor-pointer"
            onClick={() => {
                setValue();
            }}
        >
            <Icon type="add" className={{ wrapper: "fill-light-100" }} />
            <span className="w-full truncate">{value}</span>
        </div>
    );
};

const DropDown = ({ options, onChange, menuIsOpen, setMenuIsOpen }) => {
    const [visible, setVisible] = useState(false);

    const handleValueSet = (value) => {
        setMenuIsOpen(false);
        onChange(value);
    };

    useEffect(() => {
        setVisible(menuIsOpen);
    }, [menuIsOpen]);

    return (
        <div
            className={`w-full px-2 flex-col gap-1 max-h-[216px] overflow-y-scroll scrollbar ${
                visible ? "flex" : "hidden"
            }`}
        >
            {options.length > 0 ? (
                options.map((value, idx) => {
                    return (
                        <DropDownItem
                            key={idx}
                            value={value}
                            setValue={() => handleValueSet(value)}
                        />
                    );
                })
            ) : (
                <div className="flex flex-row">
                    <div className="px-2.5 text-lg font-normal text-grey-subtext truncate">
                        No Items found
                    </div>
                </div>
            )}
        </div>
    );
};

export default DropDown;

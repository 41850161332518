import BgDecorator from "@components/BgDecorator";
import BrandLoaderIcon from "@components/BrandLoaderIcon";
import { GenerationProvider } from "@components/GenerationForm/GenerationContext";
import GenerationStatusBar from "@components/GenerationStatusBar/index";
import Navbar from "@components/GlobalNavBar";
import { GTMContextProvider } from "@components/GTM/GTMContext";
import PrivateRoute from "@components/PrivateRoute";
import React, { Suspense } from "react";
import { hot } from "react-hot-loader";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from "./routes";

const App = () => {
    const [authDetails] = useSelector((state) => [state.authenticationDetails]);
    const [{ organization }, { isLoaderOverlayVisible }] = useSelector((state) => [
        state.organizationDetails,
        state.menuDetails,
    ]);
    return (
        <BrowserRouter>
            <GTMContextProvider>
                <Suspense fallback={<></>}>
                    <GenerationProvider>
                        <Navbar user={authDetails.user} organization={organization} />
                        <div className="w-screen h-[calc(100vh_-_188px)]">
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route path={route.path} key={index}>
                                        <PrivateRoute
                                            component={route.component}
                                            guard={route.guard}
                                            path={route.path}
                                        />
                                    </Route>
                                ))}
                            </Switch>
                            {authDetails.user && organization && (
                                <>
                                    <BgDecorator />
                                    <GenerationStatusBar />
                                </>
                            )}
                        </div>
                        {isLoaderOverlayVisible && (
                            <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                                <BrandLoaderIcon className="h-20 w-20" />
                            </div>
                        )}
                    </GenerationProvider>
                </Suspense>
            </GTMContextProvider>
        </BrowserRouter>
    );
};

export default hot(module)(App);

import URLS from "@utils/apiUrls";
import ApiService from "./api.service";

const UserService = {
    verfiySessionToken(tokenID) {
        const axiosOptions = {
            params: { tokenID },
        };
        return ApiService.post(URLS.setCookieToHinata, axiosOptions);
    },

    getCurrentUserSession() {
        let axiosOptions = {};
        return ApiService.get(URLS.currentUserSession, axiosOptions);
    },
    // getCurrentUserSession() {
    //     const useMockResponse = true; // Set to false to return 401 error
    //     return new Promise((resolve, reject) => {
    //         if (useMockResponse) {
    //             // Mock successful response
    //             resolve({
    //                 status: 200,
    //                 data: {
    //                     session: {
    //                         cookie: {
    //                             originalMaxAge: 2629800000,
    //                             expires: "2024-08-16T19:42:26.604Z",
    //                             secure: true,
    //                             httpOnly: true,
    //                             domain: ".pixelbin.io",
    //                             path: "/",
    //                             sameSite: "none",
    //                         },
    //                         passport: {
    //                             user: {
    //                                 _id: "41cf6a096bb7c18c2ed1d9ff",
    //                                 email: "calvindsouza@gofynd.com",
    //                                 username: "calvindsouza@gofynd.com",
    //                                 firstName: "Calvin",
    //                                 lastName: "D'Souza",
    //                                 profilePicUrl:
    //                                     "https://lh3.googleusercontent.com/a/AEdFTp6xF0xRqyfI_C39EUtIk6VA7R5YvjNPD0jFje0F=s96-c",
    //                                 active: true,
    //                                 generationsLeft: 10,
    //                                 createdAt: "2023-01-06T09:48:27.351Z",
    //                                 updatedAt: "2024-03-26T11:22:32.354Z",
    //                                 meta: {
    //                                     phoneNumber: "+919619356763",
    //                                     problemStatement: "Transforming Images in Bulk",
    //                                     usageRequirement: "0 - 500",
    //                                     referralEventSent: true,
    //                                     registrationMethod: "google",
    //                                 },
    //                                 config: {
    //                                     maxOrgLimit: 1,
    //                                 },
    //                             },
    //                         },
    //                         misc: {
    //                             ip: "115.117.121.194",
    //                             user_agent:
    //                                 "Mozilla/5.0 (Macintosh; Intel Mac OS X 14_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36",
    //                             headers: {
    //                                 host: "api.pixelbin.io",
    //                                 "x-request-id": "e9373afdd077ce6f2117649195c93324",
    //                                 "x-real-ip": "115.117.121.194",
    //                                 "x-forwarded-for": "115.117.121.194",
    //                                 "x-forwarded-host": "api.pixelbin.io",
    //                                 "x-forwarded-port": "80",
    //                                 "x-forwarded-proto": "https",
    //                                 "x-forwarded-scheme": "https",
    //                                 "x-scheme": "https",
    //                                 "x-original-forwarded-for":
    //                                     "115.117.121.194,172.69.94.169,34.160.55.240",
    //                                 "x-skip-cors": "true",
    //                                 "content-length": "1364",
    //                                 "cdn-loop": "cloudflare",
    //                                 "cf-ipcountry": "IN",
    //                                 "accept-encoding": "gzip, br",
    //                                 "cf-ray": "8a491c3e18a53f03-BOM",
    //                                 "cf-visitor": '{"scheme":"https"}',
    //                                 pragma: "no-cache",
    //                                 "cache-control": "no-cache",
    //                                 "sec-ch-ua": '""',
    //                                 "sec-ch-ua-mobile": "?0",
    //                                 "sec-ch-ua-platform": '""',
    //                                 "upgrade-insecure-requests": "1",
    //                                 origin: "https://console.pixelbin.io",
    //                                 "content-type": "application/x-www-form-urlencoded",
    //                                 "user-agent":
    //                                     "Mozilla/5.0 (Macintosh; Intel Mac OS X 14_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36",
    //                                 accept: "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
    //                                 "sec-fetch-site": "same-site",
    //                                 "sec-fetch-mode": "navigate",
    //                                 "sec-fetch-dest": "document",
    //                                 referer: "https://console.pixelbin.io/",
    //                                 "accept-language": "en-US,en;q=0.9",
    //                                 priority: "u=0, i",
    //                                 "cf-connecting-ip": "115.117.121.194",
    //                                 "x-cloud-trace-context":
    //                                     "9b86c6a659c30774e62fca3bd83eeff7/502456791137912324",
    //                                 cookie: "_ga=GA1.1.388352929.1719904539; _gcl_au=1.1.1901286382.1719904539; _ce.irv=new; cebs=1; _ce.s=v~aa924dd0cd0bab19ab31aff1e07d003b27a10237~lcw~1719904538920~lva~1719904538920~vpv~0~lcw~1719904538921; _ce.clock_event=1; moe_uuid=b448e661-1969-4347-8249-d719c97db5b9; _ce.clock_data=-86%2C115.117.121.194%2C1%2Cdccb925e7469a2ce34151a9e7c2adc29%2CChrome%2CIN; __cf_bm=TqqoUMHqV2mBuUmDxIecf2eE4bxOPlxwnr6Uhn1HGOE-1721207539-1.0.1.1-w181OAPeVjKYUPettDHGt3QDd4Y9LAIQnA3qt1HuOHjYCVzQt3ef3s6dGuIkl00wXP44JwQzUU9JZcLU2g.lTw; _ga_MYFKDWYJ87=GS1.1.1721207539.6.0.1721207546.53.0.0; ph_phc_a7s4xRxymzUs3F8x2xht7tgrN1CR4MQ4hfwwsPm4Jf0_posthog=%7B%22distinct_id%22%3A%22c23df23e-404d-4d7d-9cf4-b55452b1f720%22%2C%22%24sesid%22%3A%5B1721207546453%2C%220190bff6-cac7-7815-850b-a3807c21c9af%22%2C1721207540423%5D%2C%22%24epp%22%3Atrue%7D",
    //                                 via: "1.1 google",
    //                                 "x-fynd-trace-id": "e9373afdd077ce6f2117649195c93324",
    //                             },
    //                         },
    //                     },
    //                     bgSession:
    //                         "s:AYRPG46TpgXlLAGCgONRTDXNMxdyLsqu.r0MZ3ajeOUKuOBHsj5QrKta/2CBde2tP2mjuEYLw0KM",
    //                     sessionID: "AYRPG46TpgXlLAGCgONRTDXNMxdyLsqu",
    //                 },
    //             });
    //         } else {
    //             // Mock unauthorized error
    //             reject({
    //                 response: {
    //                     status: 401,
    //                     data: {
    //                         authenticated: false,
    //                     },
    //                 },
    //             });
    //         }
    //     });
    // },

    createOrganization(data) {
        let axiosOptions = Object.assign(
            {},
            {
                data,
            },
        );
        return ApiService.post(URLS.createOrganization, axiosOptions);
    },

    getOrganizationDetails() {
        let axiosOptions = {};
        return ApiService.get(URLS.getOrganizationDetails, axiosOptions);
    },

    getOrganizationsForUser() {
        let axiosOptions = {};
        return ApiService.get(URLS.getOrganizationsForUser, axiosOptions);
    },

    getOrganizationDetailsById(orgId) {
        let axiosOptions = {};
        return ApiService.get(
            URLS.getOrganizationDetailsById.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    deleteAccount() {
        let axiosOptions = {};
        return ApiService.delete(URLS.deleteAccount, axiosOptions);
    },
};

export default UserService;

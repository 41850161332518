import PaymentsService from "@src/services/payments.service";

export const PaymentAction = {
    GetTotalUsage: "GET_TOTAL_USAGE",
};

export const getTotalUsage = (orgId, dispatch) => {
    PaymentsService.getTotalUsage(orgId)
        .then((response) => {
            const { used: creditsUsed, total: totalCredits } = response.data.credits;
            dispatch({
                type: PaymentAction.GetTotalUsage,
                payload: {
                    totalGenerations: Math.max(Math.floor(totalCredits / 5), 0),
                    generationsLeft: Math.max(Math.floor((totalCredits - creditsUsed) / 5), 0),
                    creditsUsed,
                    totalCredits,
                },
            });
        })
        .catch((err) => {
            console.log("Total usage fetch error", err);
        });
};

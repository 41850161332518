import URLS from "@utils/apiUrls";
import ApiService from "./api.service";

const ExploreImagesService = {
    getExploreImages(startIndex = 0, stopIndex = 25) {
        let axiosOptions = {};
        return ApiService.get(
            `${URLS.exploreImages}`
                .replace(":startIndex", startIndex)
                .replace(":stopIndex", stopIndex),
            axiosOptions,
        );
    },
    getExploreImageDetails(id) {
        let axiosOptions = {};
        return ApiService.get(`${URLS.exploreImage}`.replace(":id", id), axiosOptions);
    },
};

export default ExploreImagesService;

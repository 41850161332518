import React from "react";
import BgDecoratorAltImg from "@common/assets/BgDecoratorAlt.jpg";

const BgDecorator = () => {
    const rectPosition =
        "hidden md:block bg-cover absolute top-[-270px] left-[calc(100vw-370px)] w-[756px] h-[936px] rotate-[-162.68deg] -z-10";
    const rectAltPosition =
        "hidden md:block bg-cover bg-right-top absolute top-0 left-0 w-full h-2/3 -z-10";
    const overlayGradient = "from-[#09090AFF] mix-blend-normal to-[#09090A00]";

    return (
        <>
            {/* <div className={rectPosition} style={{ backgroundImage: `url(${BgDecoratorImg})` }} /> */}
            <div
                className={`${rectAltPosition}`}
                style={{ backgroundImage: `url(${BgDecoratorAltImg})` }}
            />
            {/* <div className={`${rectPosition}  bg-custom-gradient`} /> */}
        </>
    );
};

export default BgDecorator;

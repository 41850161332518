import React, { createContext, useContext, useEffect, useState } from "react";
import environment from "@src/environment";

const GTMContext = createContext();

export const GTM_LOADING_STATES = {
    LOADING: "loading",
    LOADED: "loaded",
    ERROR: "error",
};

const loadScriptWithFetchFirst = function (url, includeCrossOrigin = true) {
    return new Promise(function (resolve, reject) {
        fetch(url, { mode: includeCrossOrigin ? "cors" : "no-cors" })
            .then((response) => {
                if (!response.ok) {
                    return reject(
                        `Can't load script ${url} error: ${response.status}; ${response.statusText}`,
                    );
                } else {
                    return response.blob();
                }
            })
            .then((blob) => {
                if (blob == null) {
                    return reject(`Can't load script ${url} error: blob is null`);
                }
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
            })
            .catch((e) => {
                reject(`Request failed: ${e}`);
            });
    });
};

const GTMContextProvider = ({ children }) => {
    const [GtmState, setGtmState] = useState(GTM_LOADING_STATES.LOADING);

    const loadGTMScript = (objectURL) => {
        window["dataLayer"] = window["dataLayer"] || [];
        window["dataLayer"].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
        });
        const f = document.getElementsByTagName("script")[0],
            j = document.createElement("script"),
            dl = "&l=dataLayer";

        j.onload = (e) => {
            console.log("GTM Loaded", e);
            setGtmState(GTM_LOADING_STATES.LOADED);
        };
        j.onerror = (e) => {
            console.log("Error loading GTM");
            setGtmState(GTM_LOADING_STATES.ERROR);
        };

        j.async = true;
        j.defer = true;
        const i = environment.GTM_CONTAINER_ID;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f?.parentNode?.insertBefore(j, f);
    };

    useEffect(() => {
        if (environment.VALUE !== "development" && environment.GTM_CONTAINER_ID) {
            const i = environment.GTM_CONTAINER_ID;
            const gtmUrl = `https://www.googletagmanager.com/gtm.js?id=${i}&l=dataLayer`;
            loadScriptWithFetchFirst(gtmUrl, true)
                .then((objectURL) => {
                    loadGTMScript(objectURL);
                })
                .catch((error) => {
                    console.log("Error loading GTM", error);
                    setGtmState(GTM_LOADING_STATES.ERROR);
                });
        } else {
            console.log("Dev env GTM");
            setGtmState(GTM_LOADING_STATES.ERROR);
        }
    }, []);

    return <GTMContext.Provider value={{ GtmState }}>{children}</GTMContext.Provider>;
};

const useGTM = () => useContext(GTMContext);

export { GTMContextProvider, useGTM };

import React from "react";
import Icon from "@components/Icon";
import { ReactComponent as TooltipIcon } from "@public/TooltipIcon.svg";

const IconButton = ({
    as,
    onClick,
    tooltipLabel,
    tooltipDirection = "bottom",
    type,
    className,
    viewBox,
    src,
    disabled,
    ...props
}) => {
    const defaultElement = "button";

    const Component = as || defaultElement;

    const getTooltipInDirection = (direction) => {
        switch (direction) {
            case "top":
                return (
                    <div className="absolute -top-8 inset-x-0 flex justify-center items-center z-20 pointer-events-none">
                        <div
                            className={`relative bottom-[-3px] hidden h-6 bg-grey-600 items-center rounded-4 text-base font-normal px-2 py-0.5 text-nowrap ${
                                as
                                    ? disabled
                                        ? ""
                                        : "md:group-hover:block"
                                    : "md:group-enabled:group-hover:block"
                            }`}
                        >
                            {tooltipLabel}
                        </div>
                        <TooltipIcon
                            className={`absolute hidden -bottom-2 rotate-180 fill-grey-600 ${
                                as
                                    ? disabled
                                        ? ""
                                        : "md:group-hover:block"
                                    : "md:group-enabled:group-hover:block"
                            }`}
                        />
                    </div>
                );
            case "bottom":
            default:
                return (
                    <div className="absolute -bottom-8 inset-x-0 flex justify-center items-center z-20 pointer-events-none">
                        <div
                            className={`relative top-[-3px] hidden h-6 bg-grey-600 items-center rounded-4 text-base font-normal px-2 py-0.5 text-nowrap ${
                                as
                                    ? disabled
                                        ? ""
                                        : "md:group-hover:block"
                                    : "md:group-enabled:group-hover:block"
                            }`}
                        >
                            {tooltipLabel}
                        </div>
                        <TooltipIcon
                            className={`absolute hidden -top-2 fill-grey-600 ${
                                as
                                    ? disabled
                                        ? ""
                                        : "md:group-hover:block"
                                    : "md:group-enabled:group-hover:block"
                            }`}
                        />
                    </div>
                );
        }
    };
    return (
        <Component
            type="button"
            className={`group outline-none focus:shadow-highlight rounded-8 ${
                as
                    ? disabled
                        ? "cursor-progress"
                        : "hover:bg-grey-600 *:hover:fill-white"
                    : "enabled:hover:bg-grey-600 enabled:*:hover:fill-white"
            } ${className?.button ?? ""}`}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            <div className="relative">
                {type === "image" ? (
                    <div className="w-10 h-10 flex justify-center items-center">
                        <img src={src} className="h-6 rounded-full" draggable={false} />
                    </div>
                ) : (
                    <Icon type={type} className={className} viewBox={viewBox} />
                )}
                {tooltipLabel && getTooltipInDirection(tooltipDirection)}
            </div>
        </Component>
    );
};

export default IconButton;

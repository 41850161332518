import URLS from "@utils/apiUrls";
import ApiService from "./api.service";

const OrgService = {
    //   createOrganization(data) {
    //     // const storeState = store.getState();
    //     let axiosOptions = Object.assign(
    //       {},
    //       {
    //         data,
    //       },
    //     );
    //     return ApiService.post(URLS.createOrganization, axiosOptions);
    //   },

    createApp(orgId, data) {
        let axiosOptions = { data };
        return ApiService.post(`${URLS.createApp}`.replace(":orgId", orgId), axiosOptions);
    },

    getAppsForOrganization(orgId, queryString) {
        let axiosOptions = {};
        return ApiService.get(
            `${URLS.getApps}?${queryString}&sortBy=updatedAt&order=desc`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    selectAppForOAuth(orgId, appId) {
        let axiosOptions = {};
        return ApiService.post(
            `${URLS.selectApps}`.replace(":orgId", orgId).replace(":appId", appId),
            axiosOptions,
        );
    },

    updateOrg(data, orgId) {
        let axiosOptions = {
            data,
        };
        return ApiService.patch(
            `${URLS.updateOrganizationDetailsById}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    updateApp(orgId, appId, data) {
        let axiosOptions = {
            data,
        };
        return ApiService.patch(
            `${URLS.updateApp}`.replace(":orgId", orgId).replace(":appId", appId),
            axiosOptions,
        );
    },
    getAppDetails(orgId, appId) {
        let axiosOptions = {};
        return ApiService.get(
            `${URLS.getAppDetails}`.replace(":orgId", orgId).replace(":appId", appId),
            axiosOptions,
        );
    },
    deleteApp(orgId, appId) {
        let axiosOptions = {};
        return ApiService.delete(
            `${URLS.deleteApp}`.replace(":orgId", orgId).replace(":appId", appId),
            axiosOptions,
        );
    },
    createTeamMember(orgId, data) {
        let axiosOptions = { data };
        return ApiService.post(`${URLS.createTeamMember}`.replace(":orgId", orgId), axiosOptions);
    },
    getTeam(orgId, userId, page, pageSize) {
        let axiosOptions = {};
        if (!userId) {
            userId = "";
        }
        if (!page) {
            page = "";
        }
        if (!pageSize) {
            pageSize = "";
        }
        return ApiService.get(
            `${URLS.getTeam}`
                .replace(":orgId", orgId)
                .replace(":userId", userId)
                .replace(":page", page)
                .replace(":pageSize", pageSize),
            axiosOptions,
        );
    },
    getTeamMember(orgId, memberId) {
        let axiosOptions = {};
        return ApiService.get(
            `${URLS.getTeamMember}`.replace(":orgId", orgId).replace(":memberId", memberId),
            axiosOptions,
        );
    },
    updateTeamMember(orgId, memberId, data) {
        let axiosOptions = { data };
        return ApiService.patch(
            `${URLS.updateTeamMember}`.replace(":orgId", orgId).replace(":memberId", memberId),
            axiosOptions,
        );
    },
    searchUsers(orgId, email) {
        let axiosOptions = {};
        return ApiService.get(
            `${URLS.searchUsers}`.replace(":orgId", orgId).replace(":email", email),
            axiosOptions,
        );
    },
    deleteTeamMember(orgId, memberId) {
        let axiosOptions = {};
        return ApiService.delete(
            `${URLS.deleteTeamMember}`.replace(":orgId", orgId).replace(":memberId", memberId),
            axiosOptions,
        );
    },

    getRoles() {
        return ApiService.get(`${URLS.getRoles}`, {});
    },

    getDomains(orgId, searchQuery, page, pageSize) {
        let axiosOptions = {};
        if (!searchQuery) {
            searchQuery = "";
        }
        if (!page) {
            page = "";
        }
        if (!pageSize) {
            pageSize = "";
        }
        return ApiService.get(
            `${URLS.getDomains}`
                .replace(":orgId", orgId)
                .replace(":searchQuery", searchQuery)
                .replace(":page", page)
                .replace(":pageSize", pageSize),
            axiosOptions,
        );
    },

    createDomain(orgId, data) {
        let axiosOptions = { data };
        return ApiService.post(`${URLS.createDomain}`.replace(":orgId", orgId), axiosOptions);
    },

    getDomainDetails(orgId, domainId) {
        let axiosOptions = {};

        return ApiService.get(
            `${URLS.getDomainDetails}`.replace(":orgId", orgId).replace(":domainId", domainId),
            axiosOptions,
        );
    },

    deleteDomain(orgId, domainId) {
        let axiosOptions = {};
        return ApiService.delete(
            `${URLS.deleteDomain}`.replace(":orgId", orgId).replace(":domainId", domainId),
            axiosOptions,
        );
    },
};

export default OrgService;

const promptCategories = [
    {
        value: "festival",
        label: "Festivals",
        options: [
            "Vibrant Krishna Janmashtami celebration, log pehene hue hain traditional kapde.",
            "Diwali ki roshni se bhari raat, patakhe aur deepak jagmagate hue.",
            "Rang-birangi Holi, log rangon ke gulal ke saath utsav mana rahe hain.",
        ],
    },
    {
        value: "food",
        label: "Food",
        options: [
            "Patra ke slices safai se plate mein sajae hue hain, upar se taaza dhaniya se garnish kiya gaya hai, aur saath mein teekhi chutney bhi hai.",
            "Hearty Litti Chokha freshly made with side dishes.",
            "Savory Masala Dosa served hot with dipping sauces.",
            "Garam-garam Masala Dosa, chutney aur sambar ke saath serve kiya gaya hai.",
            "Pav Bhaji, taaza banaa hua street food stall par serve kiya gaya.",
            "Meetha Rasgulla, taaza banaa hua, saath mein kuch side dishes ke saath.",
            "Meethi Gud Papdi, utsav ke palon ke liye.",
        ],
    },
    {
        value: "monument",
        label: "Monuments",
        options: [
            "Shandar Junagarh Fort, Bikaner mein suryast ke waqt.",
            "Historic Golconda Fort illuminated at night.",
            "Mashhoor Hawa Mahal, Jaipur mein paryatakon ke saath.",
            "Sundar Nek Chand ka Rock Garden, paryatakon ke saath.",
            "Sacred Golden Temple in Amritsar with pilgrims.",
            "Pavitra Bodh Gaya, ek tyohar ke dauran yatriyon ke saath.",
            "Madurai Meenakshi Temple in Tamil Nadu, with its towering gopurams covered in colorful statues, surrounded by bustling streets.",
            "Gateway of India in Mumbai at dawn with boats floating on the Arabian Sea, and the structure glowing in the early morning light.",
        ],
    },
    {
        value: "people",
        label: "People",
        options: [
            "Kashmir mein mahilaen saffron ka kaat rahe hain, bade baag mein unhe purple phool chunaate hue, peeche snow-covered Pir Panjal range hai.",
            "Kalakaar haath se banaaye gaye kaghaz par intricate Madhubani designs paint kar rahe hain, unke haath steady aur delicate hain jaise wo lok-katha ke rangin manzar zinda kar rahe hain.",
            "Kerala mein ek traditional Kathakali performance, dancer bhayanak chehre ka rang aur ornate costume mein hai, mandir ke courtyard ke lights ke neeche ek intense battle scene perform kar raha hai..",
            "Early morning scene of a Jain temple in Rajasthan, with intricately carved marble pillars and serene monks in white robes offering prayers in the quiet of dawn.",
            "Fishermen in Kerala’s backwaters hauling in their nets full of fresh fish as their traditional wooden boats float gently on the calm waters.",
            "Buddhist monks in Sikkim walking through the monastery courtyard at dawn, with colorful prayer flags fluttering in the breeze and a snow-capped mountain in the distance.",
            "The regal City Palace in Udaipur, its white façade shimmering against the blue waters of Lake Pichola, with boats ferrying visitors to nearby islands.",
            "A wedding ceremony on the beach in Goa, with the bride and groom exchanging garlands under a mandap decorated with tropical flowers, as the waves crash gently in the background.",
            "Tamil Nadu ke gaon mein bachche ek dusty field mein cricket khel rahe hain, makeshift bats aur wickets ka istemal karte hue, unki hasi gaon ki shaanti mein goonj rahi hai.",
            "Ek Sikh kisaan sarso ke khet mein hai, jo ek vibrant peela pagri pehne hue hain jo khilte phoolon se milta hai, peeche neela aasman aur sona khet dikhai de raha hai.",
        ],
    },
];

export { promptCategories };

import React from "react";

const SuggestionChip = ({ isActive, setActive, value, label }) => {
    return (
        <>
            <div
                className={`px-3 py-1.5 h-8 rounded-8 border text-center flex items-center hover:bg-grey-500 hover:border-grey-500 hover:text-grey-100 active:bg-grey-400 active:border-grey-400 select-none cursor-pointer ${
                    isActive
                        ? "bg-grey-600 text-grey-white border-grey-600"
                        : "bg-grey-800 text-grey-200 border-grey-800"
                }`}
                onClick={() => {
                    setActive();
                }}
            >
                {label}
            </div>
        </>
    );
};
const PromptSuggestionChips = ({ activeSuggestion, setActiveSuggestion, options }) => {
    return (
        <div className={`flex flex-row gap-1 py-2.5`}>
            {options.map(({ value, label, options }) => {
                return (
                    <SuggestionChip
                        isActive={value === activeSuggestion?.value}
                        setActive={() => {
                            if (value === activeSuggestion?.value) setActiveSuggestion(null);
                            else setActiveSuggestion({ value, label, options });
                        }}
                        key={value}
                        value={value}
                        label={label}
                    />
                );
            })}
        </div>
    );
};

export default PromptSuggestionChips;

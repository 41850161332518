import React from "react";
import Icon from "../Icon";
import Button from "../Button";
import { GENERATION_STATUS } from "@common/constants/constants";
import { removePendingGenerationById } from "@common/redux/actions/generationActions";
import { useDispatch } from "react-redux";

const GenerationStatusCard = ({ generation, isExpanded, onClick }) => {
    const dispatch = useDispatch();

    const cardConfig = (() => {
        switch (generation.jobStatus) {
            case GENERATION_STATUS.failure:
                return {
                    iconType: "error",
                    iconClassName: { wrapper: "fill-error" },
                    statusText: "Generation failed due to an error",
                    hasButton: false,
                };
            case GENERATION_STATUS.success:
                return {
                    iconType: "altTick",
                    iconClassName: { wrapper: "fill-success" },
                    statusText: "Generation successful",
                    hasButton: true,
                    buttonText: "View",
                    onClick: onClick.success,
                };
            case GENERATION_STATUS.accepted:
            case GENERATION_STATUS.preparing:
            case GENERATION_STATUS.running:
            default:
                if (!generation.isPolling)
                    return {
                        iconType: "hourglassTop",
                        iconClassName: { wrapper: "fill-grey-100" },
                        statusText: "Looks like it’s taking too long...",
                        hasButton: true,
                        buttonText: "Refresh",
                        onClick: onClick.notPolling,
                    };
                return {
                    iconType: "stars",
                    iconClassName: { wrapper: "fill-primary-light" },
                    statusText: "Generating Images...",
                    hasButton: true,
                    buttonText: "View",
                    onClick: onClick.polling,
                };
        }
    })();

    return (
        <>
            <div
                className={`h-17 flex-none bg-grey-700 border border-grey-600 flex flex-row justify-between rounded-12 px-2.5 py-3 transition-margin duration-300 ${
                    isExpanded ? "" : "-mt-18"
                }`}
            >
                <div className="flex flex-row gap-3">
                    <div className="h-full aspect-square flex-none rounded-full bg-grey-600 flex justify-center">
                        <Icon type={cardConfig.iconType} className={cardConfig.iconClassName} />
                    </div>
                    <div
                        className={`flex grow flex-col gap-0.5 md:max-w-52 ${
                            isExpanded ? "max-w-36 md:max-w-40" : "max-w-36 md:max-w-44"
                        }`}
                    >
                        <div className="text-base font-semibold h-4.5 w-full leading-[1.125rem] text-grey-white text-nowrap truncate">
                            {generation.prompt}
                        </div>
                        <div className="text-base font-normal leading-5 text-grey-200 text-nowrap truncate">
                            {cardConfig.statusText}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-3 items-center">
                    {cardConfig.hasButton && (
                        <Button size="small" onClick={cardConfig.onClick}>
                            {cardConfig.buttonText}
                        </Button>
                    )}
                    <button
                        onClick={() => {
                            removePendingGenerationById(generation._id, dispatch);
                        }}
                    >
                        <Icon type="close" className={{ wrapper: "fill-grey-300" }} />
                    </button>
                </div>
            </div>
        </>
    );
};

export default GenerationStatusCard;

import { combineReducers } from "redux";
import authReducer from "./reducers/auth.reducer";
import generationReducer from "./reducers/generation.reducer";
import orgReducer from "./reducers/organization.reducer";
import exploreImagesReducer from "./reducers/exploreImages.reducer";
import paymentReducer from "./reducers/payment.reducer";
import menuReducer from "./reducers/menu.reducer";

const rootReducer = combineReducers({
    authenticationDetails: authReducer,
    generationDetails: generationReducer,
    organizationDetails: orgReducer,
    exploreImageDetails: exploreImagesReducer,
    paymentDetails: paymentReducer,
    menuDetails: menuReducer,
});

export default rootReducer;

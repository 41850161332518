import { MenuAction } from "../actions/menuActions";

const initialState = {
    showTopNavbar: false,
    isLoaderOverlayVisible: false,
    showBanner: false,
    bannerHeight: 0,
};

const menuReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case MenuAction.SetShowTopNavBar:
            return {
                ...state,
                ...payload,
            };
        case MenuAction.SetLoaderOverlayVisiblity:
            return {
                ...state,
                ...payload,
            };
        case MenuAction.SetBannerHeight:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default menuReducer;

import React from "react";
import Lottie from "lottie-react";
import BDLoaderAnimation from "@public/BDLoaderAnimation.json";

const BrandLoaderIcon = ({ className }) => {
    return (
        <Lottie animationData={BDLoaderAnimation} data-testid="loader-icon" className={className} />
    );
};

export default BrandLoaderIcon;

import Button from "@components/Button";
import PopupWrapper from "@components/Popup/PopupWrapper";
import { logout } from "@reduxMain/actions/authActions";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

const LogoutPopup = ({ isOpen, setIsOpen, onCancel }) => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        setIsOpen(false);
        logout(dispatch).catch((err) => console.error(err));
    };

    return (
        <PopupWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="flex flex-col gap-10 px-8 py-7">
                <h2 className="text-white text-center text-2xl font-semibold">
                    Are you sure you want to Logout?
                </h2>
                <div className="flex flex-col gap-4 justify-center ">
                    <Button
                        onClick={handleLogout}
                        className="!bg-error-dark enabled:hover:shadow-error-dark text-white font-semibold py-2 px-4 rounded-lg h-12"
                    >
                        Log out
                    </Button>
                    <Button
                        onClick={onCancel}
                        className="!bg-grey-100 enabled:hover:shadow-grey-400 focus:border-grey-500 text-black font-semibold py-2 px-4 rounded-lg h-12"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </PopupWrapper>
    );
};

export default LogoutPopup;

const environment = (typeof window !== "undefined" && window._conf) || {
    ENV: "development",
    VALUE: "development",
    PORT: 9090,
    NAT_IP: "152.0.0.0",
    APPLE_OAUTH_DEFAULT_NAME: "Anon-5fb8c939",
    NEJI_MAIN_URL: "https://api.pixelbinz0.de/service/panel/users",
    NEJI_WEBHOOK_URL: "https://api.pixelbinz0.de/service/webhook/users",
    HIRUZEN_MAIN_URL: "https://api.pixelbinz0.de/service/panel/organization",
    RUPIKA_PNL_URL: "https://api.pixelbinz0.de/service/panel/payment",
    MITSUKI_MAIN_DOMAIN: "https://www.pixelbinz0.de",
    JIRAIYA_PNL_URL: "https://api.pixelbinz0.de/service/panel/transformation",
    BORUTO_PUBLIC_URL: "https://api.pixelbinz0.de/cms",
    HIDAN_PNL_URL: "https://api.pixelbinz0.de/service/panel/misc",
    WASABI_MAIN_DOMAIN: "https://console.bharatdiffusionz0.de",
    PIXELBIN_PLATFORM_DOMAIN: "pixelbinz0.de",
    BHARAT_DIFFUSION_PLATFORM_DOMAIN: "bharatdiffusionz0.de",
    GTM_CONTAINER_ID: "GTM-PVM7F3RB",
};

export default environment;

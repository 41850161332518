import _ from "lodash";
import environment from "@src/environment";
import ExploreImagesService from "@src/services/exploreImages.service";

export const ExploreImagesAction = {
    GetExploreImages: "GET_EXPLORE_IMAGES",
    ExploreImageFetch: "EXPLORE_IMAGE_FETCH",
    ExploreImageFetchFailed: "EXPLORE_IMAGE_FETCH_FAILED",
    SetTotalExploreImages: "SET_TOTAL_EXPLORE_IMAGES",
};

export const getExploreImages = (startIndex, stopIndex, dispatch) => {
    return new Promise((resolve, reject) => {
        ExploreImagesService.getExploreImages(startIndex, stopIndex)
            .then((res) => {
                dispatch({
                    type: ExploreImagesAction.GetExploreImages,
                    payload: res.data.data.map(({ attributes }) => {
                        return {
                            generationPrompt: attributes.generationPrompt,
                            seed: attributes.seed,
                            aspectRatio: attributes.aspectRatio,
                            generationStyle: attributes.generationStyle,
                            imageCount: attributes.imageCount,
                            id: attributes.generationId,
                            images: attributes.images.map(({ url }) => url),
                        };
                    }),
                });

                dispatch({
                    type: ExploreImagesAction.SetTotalExploreImages,
                    payload: res.data.meta.pagination.total,
                });

                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const getExploreImageDetails = (id, dispatch) => {
    return new Promise((resolve, reject) => {
        ExploreImagesService.getExploreImageDetails(id)
            .then((res) => {
                dispatch({
                    type: ExploreImagesAction.ExploreImageFetch,
                    payload: {
                        generationPrompt: res.data.generationPrompt,
                        seed: res.data.seed,
                        aspectRatio: res.data.aspectRatio,
                        generationStyle: res.data.generationStyle,
                        imageCount: res.data.imageCount,
                        id: res.data.generationId,
                        images: res.data.images.map(({ url }) => url),
                    },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: ExploreImagesAction.ExploreImageFetchFailed,
                });
                reject(err);
            });
    });
};

export const clearExploreImageDetails = (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: ExploreImagesAction.ExploreImageFetch,
                payload: {},
            });
            resolve("Explore image details cleared");
        } catch (err) {
            dispatch({
                type: ExploreImagesAction.ExploreImageFetchFailed,
            });
            reject("Explore image details not cleared");
        }
    });
};

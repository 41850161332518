const executor = async (guards) => {
    let redirectTo = "";
    for (let index = 0; index < guards.length; index++) {
        const guard = guards[index];
        const value = await guard();
        redirectTo = value;
        if (redirectTo.length > 0) {
            break;
        }
    }
    return redirectTo;
};

export default executor;

import { upsertToArray } from "@common/utils/common-utils";
import { ExploreImagesAction } from "../actions/exploreImagesActions";

const initialState = { exploreImages: [], explorePageDetails: {}, totalImages: 0 };

const exploreImagesReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ExploreImagesAction.GetExploreImages:
            const updatedHistory = [...state.exploreImages];
            payload.forEach((result) => upsertToArray(updatedHistory, result, "id"));

            return {
                ...state,
                exploreImages: [...updatedHistory],
            };
        case ExploreImagesAction.SetTotalExploreImages:
            return {
                ...state,
                totalImages: payload,
            };
        case ExploreImagesAction.ExploreImageFetch:
            return {
                ...state,
                explorePageDetails: payload,
            };
        case ExploreImagesAction.ExploreImageFetchFailed:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default exploreImagesReducer;

import { upsertToArray } from "@common/utils/common-utils";
import { GenerationAction } from "../actions/generationActions";
import { GENERATION_STATUS } from "@common/constants/constants";

const initialState = {
    generationPageDetails: {},
    generationHistory: [],
    totalGenerations: -1,
    pendingGenerations: [],
};

const generationReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GenerationAction.GenerationStartSuccess: {
            return {
                ...state,
                generationHistory: [payload, ...state.generationHistory],
                pendingGenerations: [...state.pendingGenerations, payload],
            };
        }

        case GenerationAction.GetGenerationHistory: {
            const updatedHistory = [...state.generationHistory];
            payload.results
                .map((generation) => {
                    const existingGeneration = updatedHistory.find(
                        (historyGeneration) =>
                            historyGeneration._id === generation._id &&
                            (historyGeneration.isPolling ?? false),
                    );

                    return { ...generation, isPolling: existingGeneration?.isPolling ?? false };
                })
                .forEach((result) => upsertToArray(updatedHistory, result, "_id"));
            const updatedPendingGenerations = [...state.pendingGenerations];
            updatedHistory
                .map((generation) => {
                    if (
                        [
                            GENERATION_STATUS.accepted,
                            GENERATION_STATUS.preparing,
                            GENERATION_STATUS.running,
                        ].includes(generation.jobStatus)
                    )
                        return generation;
                    else return null;
                })
                .filter((val) => val)
                .forEach((result) => upsertToArray(updatedPendingGenerations, result, "_id"));
            return {
                ...state,
                generationHistory: [...updatedHistory],
                totalGenerations: payload.total,
                pendingGenerations: [...updatedPendingGenerations],
            };
        }

        case GenerationAction.GenerationUpdate: {
            const updatedHistory = state.generationHistory.map((generation) =>
                generation._id === payload._id ? { ...generation, ...payload } : generation,
            );
            const updatedPendingGenerations = state.pendingGenerations.map((generation) =>
                generation._id === payload._id ? { ...generation, ...payload } : generation,
            );
            return {
                ...state,
                generationHistory: updatedHistory,
                pendingGenerations: updatedPendingGenerations,
            };
        }

        case GenerationAction.GenerationDeleteSuccess: {
            const updatedHistory = state.generationHistory.filter(
                (generation) => generation._id !== payload,
            );
            return {
                ...state,
                generationHistory: updatedHistory,
                totalGenerations: state.totalGenerations - 1,
            };
        }

        case GenerationAction.GenerationDetailsSetSuccess: {
            return {
                ...state,
                generationPageDetails: payload,
            };
        }

        case GenerationAction.GenerationPending: {
            return {
                ...state,
                ...payload,
            };
        }

        case GenerationAction.RemoveSinglePendingGeneration: {
            const updatedPendingGenerations = state.pendingGenerations.filter(
                (generation) => generation._id !== payload,
            );
            return {
                ...state,
                pendingGenerations: [...updatedPendingGenerations],
            };
        }

        case GenerationAction.RemoveAllPendingGenerations: {
            return {
                ...state,
                pendingGenerations: [],
            };
        }

        default:
            return state;
    }
};

export default generationReducer;

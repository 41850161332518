import { PaymentAction } from "../actions/paymentActions";

const initialState = {
    totalGenerations: -1,
    generationsLeft: -1,
    creditsUsed: -1,
    totalCredits: -1,
};

const paymentReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PaymentAction.GetTotalUsage: {
            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
};

export default paymentReducer;

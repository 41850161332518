import { useForm } from "react-hook-form";
import React, { createContext, createRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const GenerationContext = createContext();

export const GenerationProvider = ({ children }) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const [generationPrompt, setGenerationPrompt] = useState("");
    const [generationStillPending, setGenerationStillPending] = useState(false);
    const [imageCount, setImageCount] = useState(4);
    const [clearPromptOnLocationChange, setClearPromptOnLocationChange] = useState(false);
    const promptInputRef = createRef();
    const location = useLocation();

    const handleSetGenerationPrompt = (value) => {
        setValue("generationPrompt", value);
        setGenerationPrompt(value);
    };

    useEffect(() => {
        if (clearPromptOnLocationChange) {
            handleSetGenerationPrompt("");
            setClearPromptOnLocationChange(false);
        }
    }, [location.pathname]);

    return (
        <GenerationContext.Provider
            value={{
                register,
                handleSubmit,
                setValue,
                watch,
                errors,
                generationPrompt,
                handleSetGenerationPrompt,
                generationStillPending,
                setGenerationStillPending,
                imageCount,
                setImageCount,
                promptInputRef,
                setClearPromptOnLocationChange,
            }}
        >
            {children}
        </GenerationContext.Provider>
    );
};

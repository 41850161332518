import UserService from "services/user.service";
import OrgService from "services/org.service";
import { OrgAction } from "../common/redux/actions/orgActions";
import store from "../common/redux/store";
import environment from "@src/environment";
import QueryBuilder from "./Querybuilder";
import { ORG_ROUTE_REGEX } from "@common/constants/constants";
import Haikunator from "haikunator";
import { customAlphabet } from "nanoid";
import { getRedirectTo } from "@common/utils/common-utils";

const getCloudName = (haikunator) => {
    let cloudName = haikunator.haikunate();
    while (cloudName.length > 50) {
        cloudName = haikunator.haikunate();
    }
    return cloudName;
};
const OrganizationGuard = async () => {
    const storeState = store.getState();
    const path = window.location.pathname;
    const isSetupOrgPath = path.includes("setup-org");
    const isChooseOrgPath = path.includes("choose-org");
    const isHome = path.includes("organization");
    const pathArray = path.split("/");
    const currentURL = window.location.pathname + window.location.search + window.location.hash;
    const queryBuilder = new QueryBuilder(window.location.origin + currentURL);
    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get("utm_source");
    const redirectUrlParam = queryParams.get("redirectUrl");
    const redirectURL = redirectUrlParam || (currentURL.match(ORG_ROUTE_REGEX) ? currentURL : null);

    // redirect to /userinfo if user's username matches the default username
    // for apple oauth.
    if (
        storeState?.["authenticationDetails"]?.["user"]["firstName"] ===
        environment.APPLE_OAUTH_DEFAULT_NAME
    ) {
        // TODO: set up user info page component
        return `/userinfo`;
    }

    if (storeState["organizationDetails"]["organization"]) {
        // if we have current org in redux store then do not make the API call
        if (isHome) {
            // set org in url to current org
            const orgId = parseInt(pathArray[pathArray.indexOf("organization") + 1]);
            const userId = storeState["authenticationDetails"]["user"]["_id"];
            const teamResponse = await OrgService.getTeam(orgId, userId, null);

            if (orgId !== storeState["organizationDetails"]["organization"]._id) {
                store.dispatch({
                    type: OrgAction.SetCurrentOrganization,
                    payload: {
                        organization: storeState["organizationDetails"]["organization"],
                        member: teamResponse.data.team[0],
                    },
                });
            }
            store.dispatch({
                type: OrgAction.GetOrganizationDetails,
                payload: {
                    member: teamResponse.data.team[0],
                },
            });
            const redirectionUrl = getRedirectTo();
            if (redirectionUrl) return `/organization/${orgId}${redirectionUrl}`;
        }
        return "";
    } else if (storeState["organizationDetails"]["organizations"]?.length) {
        // if we have list of organizations in store
        if (isHome) {
            const orgId = parseInt(pathArray[pathArray.indexOf("organization") + 1]);
            if (
                !storeState["organizationDetails"]["organizations"].filter(
                    (org) => org._id === orgId,
                ).length
            ) {
                const userId = storeState["authenticationDetails"]["user"]["_id"];
                const teamResponse = await OrgService.getTeam(orgId, userId, null);

                store.dispatch({
                    type: OrgAction.SetCurrentOrganization,
                    payload: {
                        organization: storeState["organizationDetails"]["organization"],
                        member: teamResponse.data.team[0],
                    },
                });
                return "";
            }
        }
        return "";
    } else {
        try {
            const { data: userOrgs } = await UserService.getOrganizationsForUser().catch((err) => {
                return { data: [] };
            });
            store.dispatch({
                type: OrgAction.GetOrganizationsForUser,
                payload: { organizations: userOrgs },
            });

            let orgId =
                pathArray[pathArray.indexOf("organization") + 1] ||
                (userOrgs.length && userOrgs[0]["_id"]);

            if (!orgId) {
                const ALLOWED_CLOUD_NAME_CHARACTERS =
                    "0123456789QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm";
                const nanoid = customAlphabet(ALLOWED_CLOUD_NAME_CHARACTERS, 13);
                const haikunator = new Haikunator({
                    seed: nanoid(),
                    defaults: {
                        tokenLength: 6,
                        tokenChars: "0123456789",
                        delimiter: "-",
                        tokenHex: true,
                    },
                });

                // create default org for user
                const firstName = storeState["authenticationDetails"]["user"]["firstName"];
                const formData = {
                    name: `${firstName?.trim()}'s Organization 1`,
                    cloudName: getCloudName(haikunator),
                    strength: "1-10",
                    accountType: "individual",
                    industry: "other",
                };
                await UserService.createOrganization(formData).then(async (res) => {
                    store.dispatch({
                        type: OrgAction.CreateOrganizationSuccess,
                        payload: { isOrgRegistered: true, organization: res.data.detail },
                    });
                    orgId = res.data.detail["_id"];
                    const { data: userOrgs } = await UserService.getOrganizationsForUser();
                    store.dispatch({
                        type: OrgAction.GetOrganizationsForUser,
                        payload: { organizations: userOrgs },
                    });
                });
            }
            const res = await UserService.getOrganizationDetailsById(orgId);
            const userId = storeState["authenticationDetails"]["user"]["_id"];
            const teamResponse = await OrgService.getTeam(orgId, userId, null);
            store.dispatch({
                type: OrgAction.GetOrganizationDetails,
                payload: {
                    isOrgRegistered: true,
                    organization: res.data.detail,
                    member: teamResponse.data.team[0],
                },
            });
            return "";
        } catch (err) {
            console.error(err);
            if (isSetupOrgPath) {
                return "";
            }
            // on reload if get org by orgId or first org creation fails, should be
            return "/";
        }
    }
};

export default OrganizationGuard;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useStoreQueryParams = (options) => {
    const location = useLocation();
    const { storageKey, storageType, renameMap = {}, include, exclude } = options;

    const getStorage = () => {
        return storageType === "local" ? localStorage : sessionStorage;
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.toString()) {
            // Check if there are any query parameters
            const existingParams = JSON.parse(getStorage().getItem(storageKey) || "{}");
            const newParams = {};

            queryParams.forEach((value, key) => {
                let shouldInclude =
                    (!include || include.includes(key)) && (!exclude || !exclude.includes(key));
                if (shouldInclude) {
                    const newKey = renameMap[key] || key;
                    newParams[newKey] = value;
                }
            });

            // Merge new params with existing params
            const mergedParams = { ...existingParams, ...newParams };
            getStorage().setItem(storageKey, JSON.stringify(mergedParams));
        }
    }, [location, renameMap, include, exclude, storageType, storageKey]);

    const clearQueryParams = () => {
        getStorage().removeItem(storageKey);
    };

    const generateUrlWithQueryParams = (baseUrl) => {
        const storedParams = getStorage().getItem(storageKey);
        const params = storedParams ? JSON.parse(storedParams) : {};
        const queryParams = new URLSearchParams(params).toString();

        if (!baseUrl.endsWith("/")) {
            baseUrl += "/";
        }

        const separator = baseUrl.includes("?") ? "&" : "?";
        return `${baseUrl}${separator}${queryParams}`;
    };

    const generateUrlWithStateAndAffiliateData = (baseUrl, state = {}) => {
        const getStorage = () => {
            return storageType === "local" ? localStorage : sessionStorage;
        };

        const storedParams = JSON.parse(getStorage().getItem(storageKey) || "{}");
        const combinedState = {
            ...state,
            affiliate_data: { ...state.affiliate_data, ...storedParams },
        };
        const stateQueryParam = encodeURIComponent(JSON.stringify(combinedState));

        // Check if baseUrl already has query parameters
        const separator = baseUrl.includes("?") ? "&" : "?";
        const fullUrl = `${baseUrl}${separator}state=${stateQueryParam}`;

        return fullUrl;
    };

    const generateStateAndAffiliateSearchParams = (state = {}) => {
        const getStorage = () => {
            return storageType === "local" ? localStorage : sessionStorage;
        };

        const storedParams = JSON.parse(getStorage().getItem(storageKey) || "{}");
        const combinedState = {
            ...state,
            affiliate_data: { ...state.affiliate_data, ...storedParams },
        };
        const stateQueryParam = encodeURIComponent(JSON.stringify(combinedState));
        const searchParameters = `state=${stateQueryParam}`;

        return searchParameters;
    };

    return {
        clearQueryParams,
        generateUrlWithStateAndAffiliateData,
        generateStateAndAffiliateSearchParams,
    };
};

export default useStoreQueryParams;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useSessionStorageFromQuery = (keysToSave) => {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        keysToSave.forEach((key) => {
            const value = queryParams.get(key);
            if (value !== null) {
                sessionStorage.setItem(key, value);
            }
        });
    }, [location.search, keysToSave]);
};

export default useSessionStorageFromQuery;
